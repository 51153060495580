import { ALERT_GET_ALL, ALERT_GET_ALL_SUCCESS, ALERT_GET_ALL_FAILURE, ALERT_GET_CATEGORIES, ALERT_GET_CATEGORIES_SUCCESS, ALERT_GET_CATEGORIES_FAILURE,
    ALERT_GET_FILTERED, ALERT_GET_FILTERED_SUCCESS, ALERT_GET_FILTERED_FAILURE, ALERT_GET_DETAIL, ALERT_GET_DETAIL_SUCCESS, ALERT_GET_DETAIL_FAILURE,  ALERT_SEND_MESSAGE,
    ALERT_SEND_MESSAGE_SUCCESS, ALERT_SEND_MESSAGE_FAILURE, ALERT_SAVE_IMAGE_LINKS, ALERT_SAVE_IMAGE_LINKS_SUCCESS, ALERT_SAVE_IMAGE_LINKS_FAILURE, ALERT_SAVE_VERIFICATION, 
    ALERT_SAVE_VERIFICATION_SUCCESS, ALERT_SAVE_VERIFICATION_FAILURE, ALERT_SAVE_FOLLOWUP, ALERT_SAVE_FOLLOWUP_SUCCESS, ALERT_SAVE_FOLLOWUP_FAILURE, ALERT_SAVE_ACKNOWELDGEMENT,
    ALERT_SAVE_ACKNOWELDGEMENT_SUCCESS, ALERT_SAVE_ACKNOWELDGEMENT_FAILURE, ALERT_DELETE, ALERT_DELETE_SUCCESS, ALERT_DELETE_FAILURE, ALERT_SAVE, ALERT_SAVE_SUCCESS, ALERT_SAVE_FAILURE,
    ALERT_SAVE_CASE_MANAGER, ALERT_SAVE_CASE_MANAGER_SUCCESS, ALERT_SAVE_CASE_MANAGER_FAILURE,ALERT_GET_OUTCOME_OPTIONS, ALERT_GET_OUTCOME_OPTIONS_SUCCESS, ALERT_GET_OUTCOME_OPTIONS_FAILURE,
    ALERT_GET_ASSESS_OPTIONS, ALERT_GET_ASSESS_OPTIONS_SUCCESS, ALERT_GET_ASSESS_OPTIONS_FAILURE, alertUpdateAll, alertUpdateCategories, alertUpdateFiltered, alertUpdateDetail, alertUpdateShowVerificationModal, alertRefreshAlertDetail, alertUpdateShowFollowupModal, 
    alertUpdateNavigateToAlertId, alertUpdateShowMessageCommunityModal, alertUpdateShowAcknowledgeModal, alertGetFiltered, alertAssignCaseShowModal, alertOutcomeUpdate, alertAssessUpdate} from './alert.actions'
import { apiRequest } from '../api/api.action'
import { APP_DB } from './../../constants'
import getUnixTime from 'date-fns/getUnixTime'


export const alertGetAllSideEffect = (store) => next => action => {
    next(action)
    if (action.type === ALERT_GET_ALL) {

        store.dispatch(apiRequest(
            '/' + APP_DB + '/_design/db-app/_view/showAlerts?sorted=true',
            {
                headers: {
                    'Content-Type': 'application/json'
                },
            },
            ALERT_GET_ALL_SUCCESS, ALERT_GET_ALL_FAILURE)
        )
    }
}

export const alertGetAllProcessor = (store) => next => action => {
    next(action)
    if (action.type === ALERT_GET_ALL_SUCCESS) {
        store.dispatch(alertUpdateAll(action.payload.rows))
    }
}

export const alertGetDetailSideEffect = (store) => next => action => {
    next(action)
    if (action.type === ALERT_GET_DETAIL) {
        store.dispatch(apiRequest(
            '/' + APP_DB + '/' + action.payload,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
            },
            ALERT_GET_DETAIL_SUCCESS, ALERT_GET_DETAIL_FAILURE)
        )
    }
}

export const alertGetDetailProcessor = (store) => next => action => {
    next(action)
    if (action.type === ALERT_GET_DETAIL_SUCCESS) {
        store.dispatch(alertUpdateDetail(action.payload))
    }
}

export const alertGetCategoriesSideEffect = (store) => next => action => {
    next(action)
    if (action.type === ALERT_GET_CATEGORIES) {
        store.dispatch(apiRequest(
            '/' + APP_DB + '/_find',
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify({
                    selector:{
                      type: 'form'
                    },
                    fields: ['name']
                })
            },
            ALERT_GET_CATEGORIES_SUCCESS, ALERT_GET_CATEGORIES_FAILURE)
        )
    }
}


export const alertGetCategoriesProcessor = (store) => next => action => {
    next(action)
    if (action.type === ALERT_GET_CATEGORIES_SUCCESS) {
        store.dispatch(alertUpdateCategories(action.payload.docs))
    }
}

// filter object looks like this
// null value is no filter
// eslint-disable-next-line
const exampleFilter = {
    startDate: null,
    endDate: null,
    category: null,
    community: null,
    administrative_area_level_1: null,
    administrative_area_level_2: null,
    acknowledged: null,
    verified: null,
    sentVia: null
}
export const alertGetFilteredSideEffect = (store) => next => action => {
    next(action)
    if (action.type === ALERT_GET_FILTERED) {

        const filter = action.payload

        console.log(filter)

        store.dispatch(apiRequest(
            '/api/alerts/find?filter='+JSON.stringify(filter),
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'GET',
            },
            ALERT_GET_FILTERED_SUCCESS, ALERT_GET_FILTERED_FAILURE)
        )
    }
}
export const alertOptionGetOutcomeSideEffect = (store) => next => action => {
    next(action)
    if (action.type === ALERT_GET_OUTCOME_OPTIONS) {
        store.dispatch(apiRequest(
            '/api/alerts/find/outcome',
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'GET',
            },
            ALERT_GET_OUTCOME_OPTIONS_SUCCESS, ALERT_GET_OUTCOME_OPTIONS_FAILURE)
        )
    }
}

export const alertOptionGetAssessSideEffect = (store) => next => action => {
    next(action)
    if (action.type === ALERT_GET_ASSESS_OPTIONS) {
        console.log("requesting assess")
        store.dispatch(apiRequest(
            '/api/alerts/find/assess',
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'GET',
            },
            ALERT_GET_ASSESS_OPTIONS_SUCCESS, ALERT_GET_ASSESS_OPTIONS_FAILURE)
        )
    }
}

export const alertSaveCaseManagerSideEffect = (store) => next => action => {
    next(action)
    if (action.type === ALERT_SAVE_CASE_MANAGER) {

        const alert = action.payload

        console.log(alert)

        store.dispatch(apiRequest(
            '/api/alerts/'+alert._id +'/case_manager',
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(alert)
            },
            ALERT_SAVE_CASE_MANAGER_SUCCESS, ALERT_SAVE_CASE_MANAGER_FAILURE)
        )
    }
}

export const alertGetOutcomeOptionsProcessor = (store) => next => action => {
    next(action)
    if(action.type === ALERT_GET_OUTCOME_OPTIONS_SUCCESS) {
        store.dispatch(alertOutcomeUpdate(action.payload.docs[0]))
    }
}

export const alertGetAssessOptionsProcessor = (store) => next => action => {
    next(action)
    if(action.type === ALERT_GET_ASSESS_OPTIONS_SUCCESS) {
        store.dispatch(alertAssessUpdate(action.payload.docs[0]))
    }
}

export const alertGetFilteredProcessor = (store) => next => action => {
    next(action)
    if (action.type === ALERT_GET_FILTERED_SUCCESS) {
        action.payload.docs.forEach(alert => {
            alert.collect_time = new Date(parseInt(alert.collect_time))
        })
        store.dispatch(alertUpdateFiltered(action.payload.docs))
    }
}


export const alertSendMessageSideEffect = (store) => next => action => {
    next(action)
    if (action.type === ALERT_SEND_MESSAGE) {
        let formData = new URLSearchParams() // if the body is a URLSearchParams object then it should be serialised as application/x-www-form-urlencoded
        formData.append('alert', JSON.stringify(action.payload.alert))
        formData.append('msg', action.payload.message)

        store.dispatch(apiRequest(
            '/api/notification',
            {
                method: 'POST',
                body: formData
            },
            ALERT_SEND_MESSAGE_SUCCESS, ALERT_SEND_MESSAGE_FAILURE)
        )
    }
}

export const alertSendMessageProcessor = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === ALERT_SEND_MESSAGE_SUCCESS) {
        dispatch(alertRefreshAlertDetail())
        dispatch(alertUpdateShowMessageCommunityModal(false))
    }
}

export const alertSaveImagesSideEffect = (store) => next => action => {
    next(action)
    if (action.type === ALERT_SAVE_IMAGE_LINKS) {
        const alert = action.payload.alert
        if (alert.img_links) {
            alert.img_links = alert.img_links.concat(action.payload.links)
        } else {
            alert.img_links = action.payload.links
        }

        store.dispatch(apiRequest(
            '/' + APP_DB + '/' + alert._id,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                method: 'PUT',
                body: JSON.stringify(alert)
            },
            ALERT_SAVE_IMAGE_LINKS_SUCCESS, ALERT_SAVE_IMAGE_LINKS_FAILURE)
        )
    }
}

export const alertSaveImagesProcessor = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === ALERT_SAVE_IMAGE_LINKS_SUCCESS) {
        dispatch(alertUpdateNavigateToAlertId(action.payload.id))
    }
    if (action.type === ALERT_SAVE_IMAGE_LINKS_FAILURE) {
        // do something
    }
}

export const alertSaveAcknowledgementSideEffect = (store) => next => action => {
    next(action)
    if (action.type === ALERT_SAVE_ACKNOWELDGEMENT) {

        // alert should be already updated with the verification and documents
        const alert = action.payload

        store.dispatch(apiRequest(
            '/' + APP_DB + '/' + alert._id,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                method: 'PUT',
                body: JSON.stringify(alert)
            },
            ALERT_SAVE_ACKNOWELDGEMENT_SUCCESS, ALERT_SAVE_ACKNOWELDGEMENT_FAILURE)
        )
    }
}

export const alertSaveSideEffect = (store) => next => action => {
    next(action)
    if (action.type === ALERT_SAVE) {

        // alert should be already updated with the verification and documents
        const alert = action.payload

        console.log("Alert save side effect")

        console.log(alert)

        store.dispatch(apiRequest(
            '/' + APP_DB + '/' + alert._id,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                method: 'PUT',
                body: JSON.stringify(alert)
            },
            ALERT_SAVE_SUCCESS, ALERT_SAVE_FAILURE)
        )
    }
}

export const alertDeleteSideEffect = (store) => next => action => {
    next(action)
    if (action.type === ALERT_DELETE) {

        const alert = action.payload

        console.log("[Alert] deleting")

        store.dispatch(apiRequest(
            '/' + APP_DB + '/' + alert._id+'?rev='+alert._rev,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'DELETE'
            },
            ALERT_DELETE_SUCCESS, ALERT_DELETE_FAILURE)
        )
    }
}

export const alertDeleteProcessor = (store) => next => action => {
    next(action)
    if(action.type === ALERT_DELETE_SUCCESS) {
        console.log("refreshing after deletion")
        // console.log(action.payload)
        // store.dispatch(alertGetFiltered())
    }

    if(action.type == ALERT_DELETE_FAILURE) {
        // show modal alert could not be deleted
    }
}

export const alertSaveAcknowledgementProcessor = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === ALERT_SAVE_ACKNOWELDGEMENT_SUCCESS) {
        dispatch(alertUpdateShowAcknowledgeModal(false))
        dispatch(alertRefreshAlertDetail())
    }
    if (action.type === ALERT_SAVE_ACKNOWELDGEMENT_FAILURE) {
        // do something
    }
}

export const alertSaveProcessor = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === ALERT_SAVE_SUCCESS) {
        dispatch(alertAssignCaseShowModal(false))
        dispatch(alertRefreshAlertDetail())
    }
}

export const alertSaveCaseManagerProcessor = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === ALERT_SAVE_CASE_MANAGER_SUCCESS) {
        dispatch(alertAssignCaseShowModal(false))
        dispatch(alertRefreshAlertDetail())
    }
}

export const alertSaveVerificationSideEffect = (store) => next => action => {
    next(action)
    if (action.type === ALERT_SAVE_VERIFICATION) {

        // alert should be already updated with the verification and documents
        const alert = action.payload

        store.dispatch(apiRequest(
            '/' + APP_DB + '/' + alert._id,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                method: 'PUT',
                body: JSON.stringify(alert)
            },
            ALERT_SAVE_VERIFICATION_SUCCESS, ALERT_SAVE_VERIFICATION_FAILURE)
        )
    }
}

export const alertSaveVerificationProcessor = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === ALERT_SAVE_VERIFICATION_SUCCESS) {
        dispatch(alertUpdateShowVerificationModal(false))
        dispatch(alertRefreshAlertDetail())
    }
    if (action.type === ALERT_SAVE_VERIFICATION_FAILURE) {
        // do something
    }
}

export const alertSaveFollowupSideEffect = (store) => next => action => {
    next(action)
    if (action.type === ALERT_SAVE_FOLLOWUP) {

        // alert should be already updated with the followup and documents
        const alert = action.payload

        store.dispatch(apiRequest(
            '/' + APP_DB + '/' + alert._id,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                method: 'PUT',
                body: JSON.stringify(alert)
            },
            ALERT_SAVE_FOLLOWUP_SUCCESS, ALERT_SAVE_FOLLOWUP_FAILURE)
        )
    }
}

export const alertSaveFollowupProcessor = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === ALERT_SAVE_FOLLOWUP_SUCCESS) {
        dispatch(alertUpdateShowFollowupModal(false))
        dispatch(alertRefreshAlertDetail())
    }
    if (action.type === ALERT_SAVE_FOLLOWUP_FAILURE) {
        // do something
    }
}


export const alertMiddleware = [
    alertGetAllSideEffect,
    alertGetAllProcessor,
    alertGetDetailSideEffect,
    alertGetDetailProcessor,
    alertGetCategoriesSideEffect,
    alertGetCategoriesProcessor,
    alertGetFilteredSideEffect,
    alertGetFilteredProcessor,
    alertSendMessageSideEffect,
    alertSendMessageProcessor,
    alertSaveImagesSideEffect,
    alertSaveImagesProcessor,
    alertSaveAcknowledgementSideEffect,
    alertSaveAcknowledgementProcessor,
    alertSaveVerificationSideEffect,
    alertSaveVerificationProcessor,
    alertSaveFollowupSideEffect,
    alertSaveFollowupProcessor,
    alertDeleteSideEffect,
    alertDeleteProcessor,
    alertSaveSideEffect,
    alertSaveProcessor,
    alertSaveCaseManagerSideEffect,
    alertSaveCaseManagerProcessor,
    alertOptionGetOutcomeSideEffect,
    alertGetOutcomeOptionsProcessor,
    alertOptionGetAssessSideEffect,
    alertGetAssessOptionsProcessor
]