export const ALERT_GET_ALL = '[Alert] get all'
export const ALERT_GET_ALL_SUCCESS = '[Alert] get all success'
export const ALERT_GET_ALL_FAILURE = '[Alert] get all failure'
export const ALERT_UPDATE_ALL = '[Alert] update all'

export const ALERT_GET_DETAIL = '[Alert] get detail'
export const ALERT_GET_DETAIL_SUCCESS = '[Alert] get detail success'
export const ALERT_GET_DETAIL_FAILURE = '[Alert] get detail failure'
export const ALERT_UPDATE_DETAIL = '[Alert] update detail'
export const ALERT_CLEAR_DETAIL = '[Alert] clear detail'

export const ALERT_GET_CATEGORIES = '[Alert] get categories'
export const ALERT_GET_CATEGORIES_SUCCESS = '[Alert] get categories success'
export const ALERT_GET_CATEGORIES_FAILURE = '[Alert] get categories failure'
export const ALERT_UPDATE_CATEGORIES = '[Alert] update categories'

export const ALERT_GET_FILTERED = '[Alert] get filtered'
export const ALERT_GET_FILTERED_SUCCESS = '[Alert] get filtered success'
export const ALERT_GET_FILTERED_FAILURE = '[Alert] get filtered failure'
export const ALERT_UPDATE_FILTERED = '[Alert] update filtered'
export const ALERT_CLEAR_FILTERED = '[Alert] clear filtered'

export const ALERT_UPDATE_ALERT_PAGE_FILTERS = '[Alert] update alert page filters'

export const ALERT_UPDATE_DISPLAY_TYPE = '[Alert] update display type'

export const ALERT_SEND_MESSAGE = '[Alert] send message'
export const ALERT_SEND_MESSAGE_SUCCESS = '[Alert] send message success'
export const ALERT_SEND_MESSAGE_FAILURE = '[Alert] send message failure'

export const ALERT_SAVE_IMAGE_LINKS = '[Alert] save image links'
export const ALERT_SAVE_IMAGE_LINKS_SUCCESS = '[Alert] save image links success'
export const ALERT_SAVE_IMAGE_LINKS_FAILURE = '[Alert] save image links failure'

export const ALERT_SAVE_ACKNOWELDGEMENT = '[Alert] save acknowledgement'
export const ALERT_SAVE_ACKNOWELDGEMENT_SUCCESS = '[Alert] save acknowledgement success'
export const ALERT_SAVE_ACKNOWELDGEMENT_FAILURE = '[Alert] save acknowledgement failure'

export const ALERT_SAVE_CASE_MANAGER = '[Alert] save case manager'
export const ALERT_SAVE_CASE_MANAGER_SUCCESS = '[Alert] save case manager success'
export const ALERT_SAVE_CASE_MANAGER_FAILURE = '[Alert] save case manager failure'

export const ALERT_SAVE = '[Alert] Alert saved'
export const ALERT_SAVE_SUCCESS = '[Alert] Alert save success'
export const ALERT_SAVE_FAILURE = '[Alert] Alert save failure'

export const ALERT_DELETE = '[Alert] delete'
export const ALERT_DELETE_SUCCESS = '[Alert] delete success'
export const ALERT_DELETE_FAILURE = '[Alert] delete failure'

export const ALERT_SAVE_VERIFICATION = '[Alert] save verification'
export const ALERT_SAVE_VERIFICATION_SUCCESS = '[Alert] save verification success'
export const ALERT_SAVE_VERIFICATION_FAILURE = '[Alert] save verification failure'

export const ALERT_SAVE_FOLLOWUP = '[Alert] save followup'
export const ALERT_SAVE_FOLLOWUP_SUCCESS = '[Alert] save followup success'
export const ALERT_SAVE_FOLLOWUP_FAILURE = '[Alert] save followup failure'

export const ALERT_UPDATE_SHOWACKNOWLEDGEMODAL = '[Alert] update show acknowledge Modal'
export const ALERT_UPDATE_SHOWMESSAGECOMMUNITYMODAL = '[Alert] update showMessageCommunityModal'
export const ALERT_UPDATE_SHOWVERIFICATIONMODAL = '[Alert] update showVerificationModal'
export const ALERT_UPDATE_SHOWFOLLOWUPMODAL = '[Alert] update showFollowupModal'

export const ALERT_REFRESH_ALERT_DETAIL = '[Alert] refresh alert detail'

export const ALERT_UPDATE_NAVIGATE_TO_ALERT_ID = '[Alert] update navigate to alert id'

export const ALERT_DELETE_SHOWACKNOWLEDGEMODAL = '[Alert] delete alert acknowledge modal'

export const ALERT_ASSIGN_SHOWCASEMODAL = '[Alert] Show assign case modal'

export const ALERT_GET_OUTCOME_OPTIONS = '[Alert] Get outcome'
export const ALERT_GET_OUTCOME_OPTIONS_SUCCESS = '[Alert] get outcome success'
export const ALERT_GET_OUTCOME_OPTIONS_FAILURE = '[Alert] get outcome failure'

export const ALERT_OUTCOME_UPDATE = '[Alert] outcome update'

export const ALERT_GET_ASSESS_OPTIONS = '[Alert] Get assess'
export const ALERT_GET_ASSESS_OPTIONS_SUCCESS = '[Alert] get assess success'
export const ALERT_GET_ASSESS_OPTIONS_FAILURE = '[Alert] get assess failure'

export const ALERT_ASSESS_UPDATE = '[Alert] assess update'
////////////// user actions

export const alertGetAll = () => ({
    type: ALERT_GET_ALL
});

export const alertGetDetail = (alertId) => ({
    type: ALERT_GET_DETAIL,
    payload: alertId
});

export const alertGetCategories = () => ({
    type: ALERT_GET_CATEGORIES
});

export const alertGetFiltered = (filters) => ({
    type: ALERT_GET_FILTERED,
    payload: filters
});

export const alertGetOutcomeOptions = () => ({
    type: ALERT_GET_OUTCOME_OPTIONS
});

export const alertGetAssessOptions = () => ({
    type: ALERT_GET_ASSESS_OPTIONS
});


export const alertClearDetail = () => ({
    type: ALERT_CLEAR_DETAIL
});

export const alertClearFiltered = () => ({
    type: ALERT_CLEAR_FILTERED
});

export const alertUpdateAlertPageFilters = ({startDate, endDate, category, community, administrative_area_level_1, administrative_area_level_2, acknowledged, verified, action, sentVia, assigned, assessed, assessment, case_closed, outcome, isProduction}) => ({
    type: ALERT_UPDATE_ALERT_PAGE_FILTERS,
    payload: {
        startDate,
        endDate,
        category,
        community,
        administrative_area_level_1,
        administrative_area_level_2,
        acknowledged,
        verified,
        action,
        sentVia,
        assigned,
        assessed,
        assessment,
        case_closed,
        outcome,
        isProduction
    }
});

export const alertUpdateDisplayType = (displayType) => ({
    type: ALERT_UPDATE_DISPLAY_TYPE,
    payload: displayType
});


export const alertSendMessage = (alert, message) => ({
    type: ALERT_SEND_MESSAGE,
    payload: {
        alert,
        message
    }
});

export const alertSaveImageLinks = (alert, links) => ({
    type: ALERT_SAVE_IMAGE_LINKS,
    payload: {
        alert,
        links
    }
});

export const alertSave = (alert) => ({
    type: ALERT_SAVE,
    payload: alert
})

export const alertSaveCaseManager = (alert) => ({
    type: ALERT_SAVE_CASE_MANAGER,
    payload: alert
})

export const alertSaveAcknowledgement = (alert) => ({
    type: ALERT_SAVE_ACKNOWELDGEMENT,
    payload: alert
});

export const alertDelete = (alert) => ({
    type: ALERT_DELETE,
    payload: alert
})

export const alertSaveVerification = (alert) => ({
    type: ALERT_SAVE_VERIFICATION,
    payload: alert
});

export const alertSaveFollowup = (alert) => ({
    type: ALERT_SAVE_FOLLOWUP,
    payload: alert
});

export const alertUpdateShowAcknowledgeModal = (show) => ({
    type: ALERT_UPDATE_SHOWACKNOWLEDGEMODAL,
    payload: show
});

export const alertAssignCaseShowModal = (show) => ({
    type: ALERT_ASSIGN_SHOWCASEMODAL,
    payload: show
});

export const alertUpdateShowMessageCommunityModal = (show) => ({
    type: ALERT_UPDATE_SHOWMESSAGECOMMUNITYMODAL,
    payload: show
});

export const alertUpdateShowVerificationModal = (show) => ({
    type: ALERT_UPDATE_SHOWVERIFICATIONMODAL,
    payload: show
});

export const alertUpdateShowFollowupModal = (show) => ({
    type: ALERT_UPDATE_SHOWFOLLOWUPMODAL,
    payload: show
});

export const alertDeleteShowAcknowledgeModal = (show, alert) => ({
    type: ALERT_DELETE_SHOWACKNOWLEDGEMODAL,
    payload: {
        show,
        alert
    }
});

// Increments a counter in redux store
// will trigger a refresh of alert detail react page.
// Page will get the alertID from url
export const alertRefreshAlertDetail = () => ({
    type: ALERT_REFRESH_ALERT_DETAIL,
});

export const alertUpdateNavigateToAlertId = (alertId) => ({
    type: ALERT_UPDATE_NAVIGATE_TO_ALERT_ID,
    payload: alertId
});


////////////// system actions

export const alertUpdateAll = (allAlerts) => ({
    type: ALERT_UPDATE_ALL,
    payload: allAlerts
});

export const alertUpdateDetail = (alert) => ({
    type: ALERT_UPDATE_DETAIL,
    payload: alert
});

export const alertUpdateCategories = (categories) => ({
    type: ALERT_UPDATE_CATEGORIES,
    payload: categories
});

export const alertUpdateFiltered = (filteredAlerts) => ({
    type: ALERT_UPDATE_FILTERED,
    payload: filteredAlerts
});

export const alertOutcomeUpdate = (outcomes) => ({
    type: ALERT_OUTCOME_UPDATE,
    payload: outcomes
})

export const alertAssessUpdate = (assess) => ({
    type: ALERT_ASSESS_UPDATE,
    payload: assess
})