import { LOCALITIES_UPDATE_ALL, LOCALITIES_UPDATE_OBJECT, LOCALITIES_UPDATE_NETWORK_REQUEST_COMPLETE_FLAG, LOCALITIES_UPDATE_PARENTS} from './localities.actions'

const initLocalities = {
    parents: [], // names of parents of localities
    all: [],
    editObject: null, // back end has strange behaviour that requires modification of a large json object for create, update and delete operations
    successfulRequest: false // used to redirect from localities edit and create pages after a successful upload. Navigate to localities list
}

export function localitiesReducer(state = initLocalities, action) {
    switch(action.type) {
        case LOCALITIES_UPDATE_PARENTS:
            return {...state, parents: action.payload}
        case LOCALITIES_UPDATE_ALL:
            return {...state, all: action.payload}
        case LOCALITIES_UPDATE_OBJECT:
            return {...state, editObject: action.payload}
        case LOCALITIES_UPDATE_NETWORK_REQUEST_COMPLETE_FLAG:
            return {...state, successfulRequest: action.payload}
        default:
            return state
    }
}